<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Abonnent bearbeiten</strong> #{{ $route.params.id }}
            </slot>
          </CCardHeader>
          <CCardBody>
            <NewsletterSubscriberForm
                v-if="ready"
                @submit="editSubscriber"
                :enable-delete="true"
                :passedData="subscriber"
                :processing="processing"
                btn-text="Änderungen speichern"/>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import NewsletterSubscriberForm from "@/views/base/newsletter/NewsletterSubscriberForm";

export default {
  name: 'NewsletterSubscriberEdit',
  components: { NewsletterSubscriberForm },
  data() {
    return {
      ready: false,
      subscriber: null,
      processing: false
    }
  },
  created() {
    axios.get('/newsletter/subscriber/' + this.$route.params.id)
        .then(response => {
          this.subscriber = response.data
          this.ready = true
        }).catch(err => console.error(err))
  },
  beforeRouteLeave (to, from, next) {
    if (to.params.saved) {
      next()
      return
    }
    this.$modal.showModal(
        'default',
        'Editor wirklich schließen?',
        [
          `Es gibt noch Änderungen im Editor, die nicht gespeichert wurden und mit dieser Aktion verloren gehen.`
        ],
        () => {
          next()
        },
        () => {
          next(false)
        },
        { btnCancel: 'abbrechen', btnConfirm: 'Trotzdem schließen', btnConfirmColor: 'danger' }
    )
  },
  methods: {
    editSubscriber(data) {
      this.processing = true
      axios.put('/newsletter/subscriber/' + this.$route.params.id, data)
          .then(() => {
            this.$router.push({name: 'NewsletterSubscribers', params: { saved: '1' }})
            this.$toaster.makeToast('Erfolgreich!', "<b>Änderungen wurden gespeichert</b>")
          })
          .catch(err => {
            if (err.response) {
              this.$toaster.makeToast('Oops!', '<b>' + err.response.data.code + '</b><br>' + err.response.data.message)
            } else {
              this.$toaster.makeToast('Fehler!', err.message)
            }
          })
          .finally(() => {
            this.processing = false
          })
    }
  }
}
</script>
